import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import logo from "../../assets/img/logo_white.svg";
import {Link as LinkRouter, NavLink, useLocation} from "react-router-dom";
import {refSections} from "../../App";
import CustomizedAccordions from "../Elements/Accordion"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

export default function Sidebar({ sidebarOpen, toggleSidebar, handleNavLinks }) {

  const location = useLocation();
  const { refSectionsList, setRefSectionsList } = useContext(refSections);
    let localStorageLanguage = localStorage.getItem("language");
    const isRO = localStorageLanguage === "ro";

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          {
            location.pathname === "/" ?
                <Link onClick={() => toggleSidebar(!sidebarOpen)} activeClass={"active"} className="whiteColor" style={{ borderBottom: "none" }} to="home" spy={true} offset={-60}>
                  <LinkRouter className="whiteColor" to={"/"}><img src={logo} alt={logo} height={"55px"}/></LinkRouter>
                </Link>
                :<LinkRouter
                    onClick={() => {toggleSidebar(!sidebarOpen); if (location.pathname !== "/") { refSectionsList.sectionToScroll = "home" }}}
                    className="whiteColor"
                    style={{ padding: "10px 15px" }}
                    to={`/`}>
                  <img src={logo} alt={logo} height={"55px"}/>
                </LinkRouter>
          }
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font16 pointer">
          {
            location.pathname === "/" ?
                <Link onClick={() => toggleSidebar(!sidebarOpen)} activeClass={"active"} className="whiteColor" style={{ padding: "10px 15px" }} to="home" spy={true} offset={-60}>
                  <LinkRouter className="whiteColor" to={"/"}>Startseite</LinkRouter>
                </Link>
                :<LinkRouter
                    onClick={() => {toggleSidebar(!sidebarOpen); if (location.pathname !== "/") { refSectionsList.sectionToScroll = "home" }}}
                    className="whiteColor"
                    style={{ padding: "10px 15px" }}
                    to={`/`}>
                  Startseite
                </LinkRouter>
          }
        </li>
        {/*<li className="semiBold font16 pointer">*/}
        {/*  {*/}
        {/*    <a className="whiteColor" style={{padding: "10px 15px"}} href="tel:+41794771270">+41 79 477 12 70</a>*/}
        {/*  }*/}
        {/*</li>*/}
        <li className="semiBold font16 pointer text-center">
          {
            <div className={"d-flex flex-column justify-content-center align-items-center"} >
              <EmailOutlinedIcon className={"whiteColor me-2"}/>
              <a className="whiteColor" style={{padding: "10px 15px"}} href="mailto:support@wir-suchen-ihr-auto.ch">
                support@wir-suchen-ihr-auto.ch
              </a>
            </div>
          }
        </li>
      </UlStyle>
      {/*<UlStyle className="flexSpaceCenter">*/}

        {/*<li className="semiBold font16 pointer">*/}
        {/*  <a href="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">*/}
        {/*    Log in*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li className="semiBold font16 pointer flexCenter">*/}
        {/*  <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>*/}
        {/*    Get Started*/}
        {/*  </a>*/}
        {/*</li>*/}
      {/*</UlStyle>*/}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
