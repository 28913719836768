import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import logo from "../../assets/img/logo_white.svg";
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function Contact() {
  let localStorageLanguage = localStorage.getItem("language");
  const isRO = localStorageLanguage === "ro";
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer mb-3 mb-sm-0" to="home" offset={-80}>
              {/*<LogoImg />*/}
              <img src={logo} alt={logo} height={"58px"}/>
            </Link>
            <div>
              <div className={"d-flex align-items-center justify-content-center whiteColor font13 pointer mb-2"}>
                <PhoneIcon className={"me-2"} width={"13px"}/>
                <a className={"whiteColor"} href="tel:+41794771270">+41 79 477 12 70</a>
              </div>
              <div className={"d-flex align-items-center justify-content-center whiteColor font13 mb-2"}>
                <PlaceIcon className={"me-2"} width={"13px"}/>
                <p className={"whiteColor"}>Windblosenstrasse 5 6206 Neuenkirch</p>
              </div>
              <StyleP className="whiteColor font13 text-center">
                © {getCurrentYear()} - <span className="orangeColor font13">Billard Automotive</span> - Alle Rechte vorbehalten <br/>
              </StyleP>
            </div>
            <Link className="whiteColor animate pointer font13" to="home" offset={-80}>
              <ExpandLessIcon className={"me-1"}/>
              Zurück nach oben
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;